import $ from 'jquery';

// Actions
function updateDisplay($widget, $button, $checkbox, color, settings) {

    var isChecked = $checkbox.is(':checked');

    // Set the button's state
    $button.data('state', (isChecked) ? 'on' : 'off');

    // Set the button's icon
    $button.find('.state-icon')
        .removeClass()
        .addClass('state-icon ' + settings[$button.data('state')].icon);

    // Update the button's color
    if (isChecked) {
        $button
            .removeClass('btn-gris1')
            .addClass('btn-' + color);
    }
    else {
        $button
            .removeClass('btn-' + color)
            .addClass('btn-gris1');
    }
}

// Initialization
function init($widget, $button, $checkbox, color, settings) {

    updateDisplay($widget, $button, $checkbox, color, settings);

    // Inject the icon if applicable
    if ($button.find('.state-icon').length === 0) {
        $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
    }
}

$(document).ready(function () {
    $('.button-checkbox').each(function (index, element) {
        // Settings
        var $widget   = $(element),
            $button   = $widget.find('button'),
            $checkbox = $widget.find('input:checkbox'),
            color     = $button.data('color'),
            settings  = {
                on: {
                    icon: 'icon-checkbox-checked'
                },
                off: {
                    icon: 'icon-checkbox-unchecked'
                }
            };

        // Event Handlers
        $button.on('click', function () {
            $checkbox.prop('checked', !$checkbox.is(':checked'));
            $checkbox.triggerHandler('change');
            updateDisplay($widget, $button, $checkbox, color, settings);
        });
        $checkbox.on('change', function () {
            updateDisplay($widget, $button, $checkbox, color, settings);
        });

        init($widget, $button, $checkbox, color, settings);
    });
});
