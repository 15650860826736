import $ from 'jquery';
// import '../../../../../bower_components/slick-carousel/slick/slick';
// import 'slick-carousel';

// function runSlider()
// {
//     var $sliderMobile = $('.sliderMobile');
//     var $sliderSelection = $('.sliderSelection');
//
//     if ((matchMedia('(min-width: 1300px)').matches)) {
//         if ($sliderSelection.hasClass('slick-initialized')) {
//             $sliderSelection.slick('unslick');
//         }
//     }
//
//     if ((matchMedia('(min-width: 992px)').matches)) {
//         if ($sliderSelection.hasClass('slick-initialized')) {
//             $sliderSelection.slick('unslick');
//         }
//     }
//     else {
//         if (!$sliderMobile.hasClass('slick-initialized')) {
//             $sliderMobile.slick({
//                 infinite: true,
//                 speed: 300,
//                 cssEase: 'ease-in-out',
//                 // mobileFirst: true,
//                 autoplay: true,
//                 autoplaySpeed: 5000,
//                 responsive: [
//                     {
//                         breakpoint: 600,
//                         settings: {
//                             slidesToShow: 1,
//                             slidesToScroll: 1
//                         }
//                     }
//                 ]
//             });
//         }
//
//
//         if (!$sliderSelection.hasClass('slick-initialized')) {
//             $sliderSelection.slick({
//                 infinite: true,
//                 speed: 300,
//                 cssEase: 'ease-in-out',
//                 // mobileFirst: true,
//                 autoplay: true,
//                 autoplaySpeed: 5000,
//                 responsive: [
//                     {
//                         breakpoint: 600,
//                         settings: {
//                             slidesToShow: 1,
//                             slidesToScroll: 1
//                         }
//                     }
//                 ]
//             });
//         }
//     }
// }
//
// function carouselMdOnly(){
//     if ((matchMedia('(min-width: 992)').matches) || Modernizr.mq('(min-width: 992px)')) {
//         /*$('.carouselMd').on('slide.bs.carousel', function () {
//             $(this).carousel('dispose');
//         });*/
//         $('.carouselMd .item').removeClass('item');
//         $('.carouselMd').carousel('dispose');
//     }
// }

function activeCaroussel() {
    $('.carouselMd .carousel-inner > div').addClass('item');
    $('.carouselMd').carousel();
}

function disableCaroussel() {
    $('.carouselMd .carousel-inner > div').removeClass('item');
    $('.carouselMd').carousel('pause');
}
function isMaxWidth992() {
    return matchMedia('(max-width: 992px)').matches;
}
$(document).ready(function () {
    if (isMaxWidth992()) {
        activeCaroussel();
    } else {
        disableCaroussel();
    }
    // carouselMdOnly();
    // runSlider();
    $(window).resize(function () {
        if (isMaxWidth992()) {
            activeCaroussel();
        } else {
            disableCaroussel();
        }
        if ((matchMedia('(max-width: 992px)').matches)) {
            $('.carouselMd').on('slide.bs.carousel', function () {
                $('.carouselMd').carousel();
            });

            $('.carouselMd .item-md').addClass('item');
        } else {
            $('.carouselMd').on('slide.bs.carousel', function () {
                $('.carouselMd').carousel('pause');
            });
            $('.carouselMd .item-md').removeClass('item');
        }
    });
    if ((matchMedia('(max-width: 992px)').matches)) {
        $('.carouselMd').on('slide.bs.carousel', function () {
            $('.carouselMd').carousel();
        });

        $('.carouselMd .item-md').addClass('item');
    } else {
        $('.carouselMd').on('slide.bs.carousel', function () {
            $('.carouselMd').carousel('pause');
        });
        $('.carouselMd .item-md').removeClass('item');
    }

});
