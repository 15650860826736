import $ from 'jquery';
import {setInfiniteScroll} from './lazy-load';

function setPhotoAvisClient()
{
    var $photoAvisClient = $('.photoAvisClientToRadius');

    $photoAvisClient.css('height', ($photoAvisClient.width() / 2) + 'px')
    .css('border-radius', '0px 0px ' + ($photoAvisClient.width() / 2) + 'px ' + ($photoAvisClient.width() / 2) + 'px');

    $photoAvisClient.removeClass('photoAvisClientToRadius');
}

$(document).ready(function () {

    // setPhotoAvisClient();

    if ($('.containIndex').length === 0){

        setInfiniteScroll({
            currentScroll: 0,
            nbScrollMax: 2,
            container: '.customersReviews',
            nextLink: '.next-reviews',
            // loadingHtml: 'Chargement des avis clients...',
            loadingHtml: '<div class="col-xs-12 text-center">' + $('.loaderAvisClients').eq(0).html() + '</div>',
            callback: function (){
                setPhotoAvisClient();
            }
        });
    }

    $(window).resize(function () {
        // setPhotoAvisClient();
    });
});


