import $ from 'jquery';

export function setInfiniteScroll(data)
{
    let lazyObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadNextContent(data);
                lazyObserver.unobserve(entry.target);
            }
        });
    });

    document.querySelectorAll(data.nextLink).forEach(nextLinkElement => {
        lazyObserver.observe(nextLinkElement);
    });
}

export function loadNextContent(data)
{
    let container = $(data.container + ':last');
    let nextLink = container.find(data.nextLink + ':last');

    // On arrête l'event sur le scroll
    $(window).off('scroll');

    if (nextLink.length > 0) {

        nextLink.parent().next('div').hide();
        nextLink.parent().hide();

        container.append('<div id="loadingNextContent" class="col-md-12">' + data.loadingHtml + '</div>');

        $.ajax({
            url: nextLink.attr('href') + window.location.search,
            dataType: 'html',
            success: function (response) {

                let html = $(response);
                let content = html.find(data.container);

                $(content.html()).insertBefore(nextLink.parent());
                nextLink.parent().remove();

                $('#loadingNextContent').remove();

                setInfiniteScroll(data);

                if (typeof data.callback === 'function') {
                    var callback = new data.callback;
                }
            }
        });

    }
}
