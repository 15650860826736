import $ from 'jquery';

function setCollapseMobile() {
    if ((matchMedia('(max-width: 991px)').matches)) {
        $('.mandataireTitre').attr('data-toggle', 'collapse');
        $('.mandataireTxt').removeClass('in');
    }
    else {
        $('.mandataireTitre').removeAttr('data-toggle');
        $('.mandataireTxt').addClass('in');
    }
}

$(document).ready(function () {
    setCollapseMobile();
});

$(window).resize(function () {
    setCollapseMobile();
});
